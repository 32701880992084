import { RefObject, useRef } from "react";
import { getMaps } from "../data/maps";
import { Entity, getEntities } from "../model/entities";
import MapImage from "./MapImage";

 
const Analysis = () => {

   return <>
   { Array.from({ length: 50 }).map((_, stage) =>
      getMaps(stage + 1).map((map, n) => {
         var counts = map.reduce((acc, row) => row.reduce((acc, cell) => { if (cell) { acc[cell] = (acc[cell]||0) + 1; } return acc; }, acc), {} as Partial<Record<Entity, number>>);
         var unknowns = map.reduce((acc, row) => row.reduce((acc, cell) => { if (!cell) { acc++; } return acc; }, acc), 0);
         var missing = getEntities().map(([name, entity]) => {
            if (entity.count && entity.count(stage+1) !== (counts[name]||0)) {
               return <div key={name}>{name} {entity.count(stage+1) - (counts[name]||0)}</div>;
            }
         }).filter(_ => _);
         return unknowns > 0 ? <div key={`${stage}-${map}`}>
            <MapImage stage={stage+1} map={n}/>
            {missing}
         </div> : undefined;
      })
   )}
   </>;
};
 
export default Analysis;
